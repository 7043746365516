<template>
  <b-container
    fluid
    class="text-center font-weight-bold"
    :class="theme === 'dark' ? 'dark-footer' : 'light-footer'"
  >
    <p>&copy; Yoseph Martin Christian. All Rights Reserved.</p>
  </b-container>
</template>

<style scoped>
.dark-footer {
  background-color: black;
  color: white;
}

.light-footer {
  background-color: white;
  color: black;
}

p {
  padding: 0.2rem 0;
  font-size: 0.8rem;
  margin-bottom: 0;
}
</style>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "light",
    },
  },
};
</script>
