<template>
  <div>
    <div>
      <!-- SECTION HEADLINE -->
      <div class="section-light" id="sectionTitle">
        <b-container>
          <b-row>
            <b-col></b-col>
            <b-col sm="12" md="10">
              <div class="sectionHeadline">
                <h1>Activities</h1>
                <p>
                  Side activities that I participate and take part during my
                  free time
                </p>
              </div>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <!-- CLUBS & ORGANIZATIONS -->
    <content-section theme="dark">
      <b-row>
        <b-col class="text-center">
          <h1>Clubs &amp; Organizations</h1>
          <p>These are the clubs and organizations that I joined for fun.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- CLUBS 1 -->
          <info-card theme="dark">
            <h2 slot="title">Google Developer Group Kuala Lumpur (GDGKL)</h2>
            <p slot="tagline">
              Sharing development trends, experiences, and tips with other
              developers.
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in googleDeveloperCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                This is a developer group that gather to talk about current
                technology trends, new technology implementation techniques, and
                the future of technologies. A lot of technical talks, but
                interesting to follow.
              </p>
              <p class="highlighted">Member since April 2018 - January 2020</p>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- CLUBS 2 -->
          <info-card theme="dark">
            <h2 slot="title">Toastmasters International</h2>
            <p slot="tagline">Where leaders are made.</p>
            <b-img
              center
              slot="custom-image"
              :src="toastmastersImage"
              fluid
              alt="Toastmasters International"
            ></b-img>
            <div slot="description">
              <p>
                As the slogan suggests, great leader must be able to deliver
                great talk. I joined the Toastmasters club to learn how to
                deliver a speech and to eliminate the fear of public speaking.
                Fun fact: Most people are more afraid of public speaking than
                death.
              </p>
              <p class="highlighted">
                Member since November 2017 - January 2020
              </p>
            </div>
          </info-card>
        </b-col>
      </b-row>
    </content-section>
    <!-- VOLUNTEERING -->
    <content-section theme="light">
      <b-row>
        <b-col class="text-center">
          <h1>Volunteering</h1>
          <p>Giving back to the community.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- VOLUNTEERING 1 -->
          <info-card>
            <h2 slot="title">Indonesia Independence Day (2018)</h2>
            <p slot="tagline">
              Celebrating independence day of the motherland is our call of
              duty.
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in indonesiaIndependenceCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                The Indonesia Independence day falls on August 17. We are
                currently studying at Malaysia, but we know that no matter where
                we are, we still need to celebrate our independence day.
              </p>
              <p class="highlighted">Role: Logistic volunteer</p>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- VOLUNTEERING 2 -->
          <info-card>
            <h2 slot="title">Kuala Lumpur Marathon (2018)</h2>
            <p slot="tagline">
              5km marathon, 10km marathon, anything will do as long as you keep
              running.
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in kualaLumpurMarathonCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                An annual marathon event held in Kuala Lumpur. A lot of people
                participate in this marathon, starting from professional
                athletes to normal participants that want to enjoy the marathon
                experience. I'm one of the logistic member of the volunteer
                team, which task is to prepare the marathon track and supplies
                the marathon runner with water along the track.
              </p>
              <p class="highlighted">Role: Logistic volunteer</p>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- VOLUNTEERING 3 -->
          <info-card>
            <h2 slot="title">Rumah Solehah Project (2018)</h2>
            <p slot="tagline">
              Restructuring the orphanage, to let the children know that we care
              about them.
            </p>
            <b-carousel slot="custom-carousel">
              <b-carousel-slide
                v-for="(item, i) in rumahSolehahCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                This is a community service to help restructuring a home of an
                orphanage in Kuala Lumpur, Malaysia. Many teams were sent to the
                orphanage with different tasks to restructure the orphanage. We
                are not allowed to take a picture to protect the identity of the
                children.
              </p>
              <p class="highlighted">Role: Volunteer team leader</p>
            </div>
          </info-card>
        </b-col>
      </b-row>
    </content-section>
    <!-- FOOTER -->
    <footer-section theme="dark"></footer-section>
  </div>
</template>

<script>
import InformationCard from "@/components/InformationCard";
import ContentSection from "../components/ContentSection.vue";

export default {
  name: "Activities",
  components: {
    "info-card": InformationCard,
    "content-section": ContentSection,
  },
  data() {
    return {
      // Image source link
      toastmastersImage: require("@/assets/common/images/icon/activities/toastmasters.png"),

      // Carousel source link
      googleDeveloperCarousel: [
        {
          src: require("@/assets/common/images/activity/gdgkl-meetup-april-1.png"),
        },
        {
          src: require("@/assets/common/images/activity/gdgkl-meetup-april-2.png"),
        },
      ],
      indonesiaIndependenceCarousel: [
        {
          src: require("@/assets/common/images/certificate/indonesia-independence-day.png"),
        },
        {
          src: require("@/assets/common/images/activity/indonesia-independence-day-2018.png"),
        },
      ],
      kualaLumpurMarathonCarousel: [
        {
          src: require("@/assets/common/images/certificate/kl-marathon-2018.png"),
        },
        {
          src: require("@/assets/common/images/activity/kl-marathon-1-2018.png"),
        },
        {
          src: require("@/assets/common/images/activity/kl-marathon-2-2018.png"),
        },
      ],
      rumahSolehahCarousel: [
        {
          src: require("@/assets/common/images/certificate/apu-rumah-solehah.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
div#sectionTitle {
  height: 500px;
  line-height: 460px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./../assets/common/images/activity/activity-headline.png");
  filter: grayscale(1);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  padding-bottom: 0;
  padding-top: 0;
}

.sectionHeadline {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
</style>
