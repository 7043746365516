<template>
  <div>
    <!-- SECTION HEADLINE -->
    <div id="sectionTitle">
      <b-container>
        <b-row>
          <b-col></b-col>
          <b-col sm="12" md="10">
            <div class="sectionHeadline">
              <h1>Software Projects</h1>
              <p>Here are my projects that I worked on to master my craft</p>
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-container>
    </div>
    <!-- SOFTWARE PROJECTS -->
    <content-section theme="dark">
      <b-row>
        <b-col class="text-center">
          <h1>Highlighted Projects</h1>
          <p>
            Welcome to the showcase on some of my best software projects so far.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 1 -->
          <info-card id="ymc-project" theme="dark">
            <h2 slot="title">My Personal Website</h2>
            <p slot="tagline">
              Very first effort to put my mark on the world wide web |
              <strong>http://yosephmartin.com</strong>
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in ymcCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                Initially started as a personal documentation of my development
                journey since 2018, my personal website have gone through
                multiple major improvements until today. Every time there is any
                milestone that I have achieved, no matter how big or small, this
                website will be the first place that knows it. Throughout the
                time, the way I saw this website evolved from a place to
                showcase my achievements into becoming more deeper as part of my
                true identity.
              </p>
              <p>
                Please take your time to explore around my home, and enjoy your
                stay while you are here.
              </p>
              <p class="highlighted">
                Utilized: HTML, CSS, JavaScript, Vue.js, BootstrapVue, cPanel
              </p>
            </div>
            <b-button slot="button" class="button-dark big-button" disabled
              ><strong>Congratulations, you are here!</strong>
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 2 -->
          <info-card id="stufftodo-project" theme="dark">
            <h2 slot="title">Stuff To-Do</h2>
            <p slot="tagline">
              Every one of us has a lot of #StuffToDo |
              <strong>http://thestufftodo.com</strong>
            </p>
            <b-img
              center
              slot="custom-image"
              :src="stuffToDoImage"
              fluid
              alt="Stuff To-Do"
            ></b-img>
            <div slot="description">
              <p>
                Stuff To-Do is a cross-platform collaborative to-do list
                application that is available on web and mobile (Android)
                platforms. Both of these platforms have real-time data
                synchronization feature that enables Stuff To-Do to always use
                the latest data, no matter in which platform you use Stuff
                To-Do.
              </p>
              <p>
                Stuff to-do will make you able to easily manage both your
                personal or collaborative tasks. With the personal list, you
                will be able to make your own personalized list that suits your
                needs.
              </p>
              <p>
                The collaborative list, however, is the heart of Stuff To-Do.
                Stuff To-Do was developed with collaboration in mind, and thus
                designed in such a way that can enhance the collaboration
                experience through the collaborator role management feature.
              </p>
              <p>
                With Stuff To-Do, revolutionary features will be introduced and
                to-do list will not be the same anymore.
              </p>
              <p class="highlighted">
                Utilized: HTML, CSS, JavaScript, Vue.js, BootstrapVue, Android,
                Java, XML, cPanel, Firebase Firestore
              </p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="openLink('http://thestufftodo.com')"
              >Access it on <strong>thestufftodo.com</strong>
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 3 -->
          <info-card theme="dark">
            <h2 slot="title">Galaxy Shooter</h2>
            <p slot="tagline">
              A futuristic sci-fi galactic shooter against the unknown enemy in
              the deep space
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in galaxyShooterCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                A 2D arcade game that took place in the galaxy that included a
                spaceship battle. This game was made based on the Unity
                Authorized Course for game developer training.
              </p>
              <p class="highlighted">Utilized: C#, Unity</p>
              <p>How to play:</p>
              <ul>
                <li>Press the spacebar to start the game</li>
                <li>Use W, A, S, D key to move</li>
                <li>Use left mouse button to shoot</li>
                <li>
                  Grab power ups along the way and survive as long as you can!
                </li>
              </ul>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="
                openLink(
                  'http://yosephmartin.com/demo/space-shooter/index.html'
                )
              "
              >Play the game!
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 4 -->
          <info-card id="prolog-project" theme="dark">
            <h2 slot="title">ProLog</h2>
            <p slot="tagline">
              Biometric authentication application that brings a peace of mind
              to your data security
            </p>
            <b-img
              center
              slot="custom-image"
              :src="prologImage"
              fluid
              alt="ProLog"
            ></b-img>
            <div slot="description">
              <p>
                ProLog is an Android application that enable the user to do
                two-factor authentication that include user's biometric
                information for added security to access the data stored in the
                phone. At the first factor of authentication, the user can
                choose the preferred authentication method, which consist of:
              </p>
              <ul>
                <li>Password</li>
                <li>PIN</li>
                <li>Pattern</li>
              </ul>
              <p>
                After the user passed the first authentication, the second
                authentication method will be performed by using user's
                biometrics, which consist of:
              </p>
              <ul>
                <li>Fingerprint</li>
                <li>Voice*</li>
                <li>Face recognition**</li>
              </ul>
              <p>
                If the user can provide the biometric information, the user can
                access the data inside the application.
              </p>
              <p>
                * The voice feature is not recognizing the user's voice, instead
                of comparing the password phrase that can be customized by the
                user at initial application setup.
              </p>
              <p>
                ** The face recognition feature will become available in the
                future version.
              </p>
              <p class="highlighted">Utilized: Android, Java, XML</p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="openLink('https://github.com/MartinezBlaze/ProLog')"
              >Project available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
    </content-section>
    <content-section theme="light">
      <b-row>
        <b-col class="text-center">
          <h1>Courses & Competitions Projects</h1>
          <p>
            The projects that have been developed as the result of university or
            online courses and competitions that I took part in.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 5 -->
          <info-card id="university-classroom-timetable-system-project">
            <h2 slot="title">University Classroom Timetable System</h2>
            <p slot="tagline">
              Now you and your lecturer don't need to worry about going to the
              wrong class again
            </p>
            <b-img
              center
              slot="custom-image"
              :src="universityClassroomTimetableImage"
              fluid
              alt="University Classroom Timetable System"
            ></b-img>
            <div slot="description">
              <p>
                The Classroom Timetable System is a desktop software that has a
                database engine integrated to the program. This program is
                capable of recognizing the type of the user and restricting
                functionality based on the user's access.
              </p>
              <p>
                The scheduler is capable to manage the schedule for each of the
                classroom, which include:
              </p>
              <ul>
                <li>Place reservation</li>
                <li>Move reservation</li>
                <li>Delete reservation</li>
              </ul>
              <p>The user can use the software to:</p>
              <ul>
                <li>Place reservation</li>
                <li>Reschedule reservation</li>
                <li>Cancel reservation</li>
              </ul>
              <p>
                Both the scheduler and the user are also capable of looking at
                the timetable to know the usage of the class for the current
                week.
              </p>
              <p class="highlighted">Utilized: C#, Microsoft Access, SQL</p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="
                openLink(
                  'https://github.com/MartinezBlaze/University-Classroom-Timetable-System'
                )
              "
            >
              Project available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 6 -->
          <info-card id="body-mindfulness-project">
            <h2 slot="title">Body Mindfulness</h2>
            <p slot="tagline">Mindfulness starts from within</p>
            <b-img
              center
              slot="custom-image"
              :src="bodyMindfulnessImage"
              fluid
              alt="Body Mindfulness"
            ></b-img>
            <div slot="description">
              <p>
                Body Mindfulness is an iOS workout tracking application that can
                help you maintain your workout routine. The application also
                comes with workout set that can guide you to focus your workout
                on specific body parts.
              </p>
              <p>Features of the application:</p>
              <ul>
                <li>
                  Supports multiple user account with personalized information
                </li>
                <li>Built in Body Mass Index (BMI) calculator</li>
                <li>Tracks workout history</li>
                <li>Provides workout set for specific body parts</li>
                <li>
                  Built in timer to guide user through the workout session
                </li>
              </ul>
              <p>No more reason to skip your workout, right?</p>
              <p class="highlighted">Utilized: Swift</p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="
                openLink('https://github.com/MartinezBlaze/BodyMindfulness')
              "
              >Project available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 7 -->
          <info-card id="clima-project">
            <h2 slot="title">Clima</h2>
            <p slot="tagline">Go out with or without your umbrella today?</p>
            <b-img
              center
              slot="custom-image"
              :src="climaImage"
              fluid
              alt="Clima"
            ></b-img>
            <div slot="description">
              <p>
                Have you ever wondered what is the weather for today? Or maybe
                knowing the weather in advance play a big role when you plan
                your day? I got an application just for that purpose.
              </p>
              <p>Features of the application:</p>
              <ul>
                <li>
                  Checks the weather automatically at your current location
                </li>
                <li>Checks the weather for any location you want</li>
              </ul>
              <p>
                This application may not predict your destiny (for now), but at
                least it can predict the weather for you. One less thing to
                worry about in life.
              </p>
              <p class="highlighted">Utilized: Android, Flutter</p>
            </div>
            <b-button slot="button" class="button-dark big-button" disabled
              >Project currently not available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 8 -->
          <info-card id="xylophone-project">
            <h2 slot="title">Xylophone</h2>
            <p slot="tagline">Simply, a xylophone</p>
            <b-img
              center
              slot="custom-image"
              :src="xylophoneImage"
              fluid
              alt="Xylophone"
            ></b-img>
            <div slot="description">
              <p>
                The design and name of the application explain themselves. This
                is a 7-note xylophone instrument application. You can create a
                song or play a song that you already know.
              </p>
              <p>This application is capable of causing these effects:</p>
              <ul>
                <li>Makes your children happy</li>
                <li>Reminds you of your happy childhood</li>
                <li>
                  Reminds everyone who listened to your music of their happy
                  childhood
                </li>
              </ul>
              <p>
                Just play it straight away, and unleash your childhood
                creativity.
              </p>
              <p class="highlighted">Utilized: Android, Flutter</p>
            </div>
            <b-button slot="button" class="button-dark big-button" disabled
              >Project currently not available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 9 -->
          <info-card id="matrix-manipulator-project">
            <h2 slot="title">Matrix Manipulator</h2>
            <p slot="tagline">Much better than manipulating people</p>
            <b-img
              center
              slot="custom-image"
              :src="matrixManipulatorImage"
              fluid
              alt="Matrix Manipulator"
            ></b-img>
            <div slot="description">
              <p>
                The Matrix Manipulator is a program that can be used to perform
                matrix manipulation operations. This project demonstrates the
                implementation of data structure concepts such as addresses,
                pointers, and how to allocate memory effectively for the
                program.
              </p>
              <p>The matrix manipulator program is capable of:</p>
              <ul>
                <li>
                  Set up triangular matrices to perform the operations upon
                </li>
                <li>
                  Add up the values of the matrix for each corresponding row and
                  column
                </li>
              </ul>
              <p></p>
              <p class="highlighted">Utilized: C++</p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="
                openLink(
                  'https://github.com/MartinezBlaze/Matrix-LinkedList-Data-Structure'
                )
              "
              >Project available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 10 -->
          <info-card id="apartment-reservation-system-project">
            <h2 slot="title">Apartment Reservation System</h2>
            <p slot="tagline">
              Recreating the classical approach of managing the apartment
              residents
            </p>
            <b-img
              center
              slot="custom-image"
              :src="apartmentReservationSystemImage"
              fluid
              alt="Apartment Reservation System"
            ></b-img>
            <div slot="description">
              <p>
                Apartment Reservation System Recreating the classical approach
                of managing the apartment residents. The Apartment Reservation
                System is a system designed to be used in terminal or command
                line environment to help manage the management process of the
                university's apartment residents. This program can help to
                accomplish some activities, such as:
              </p>
              <ul>
                <li>Check-in new tenant</li>
                <li>Check-out existing tenant</li>
                <li>Look at report of current tenant in the apartment</li>
                <li>Search for a tenant</li>
              </ul>
              <p class="highlighted">Utilized: Python</p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="
                openLink(
                  'https://github.com/MartinezBlaze/Apartment-Reservation-System'
                )
              "
              >Project available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 11 -->
          <info-card id="justsubmit-project">
            <h2 slot="title">JustSubmit</h2>
            <p slot="tagline">
              Just submit your assignment online, no printed assignment required
            </p>
            <b-img
              center
              slot="custom-image"
              :src="justSubmitImage"
              fluid
              alt="JustSubmit"
            ></b-img>
            <div slot="description">
              <p>
                JustSubmit is an online assignment submission system that
                enables the student to submit their assignment online and
                enables the teacher to post and grade the assignment submission
                submitted by the student.
              </p>
              <p>The teacher will be able to use the system for:</p>
              <ul>
                <li>Create, update, and remove the assignment</li>
                <li>Add or remove attachment related to the assignment</li>
                <li>
                  See the student's submission and return the submission with
                  grade and feedback
                </li>
              </ul>
              <p>The student will be able to use the system for:</p>
              <ul>
                <li>Join the assignment that was posted by the teacher</li>
                <li>Check the posted assignment details</li>
                <li>Submit the work related to the assignment</li>
                <li>Receive feedback and grade related to the assignment</li>
              </ul>
              <p class="highlighted">Utilized: ASP.NET Core, Microsoft Azure</p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="openLink('https://github.com/MartinezBlaze/ProLog')"
              disabled
              >Project currently not available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 12 -->
          <info-card id="travel-journal-project">
            <h2 slot="title">TravelJournal</h2>
            <p slot="tagline">
              One big travel journal to help you decide your next vacation
              destination
            </p>
            <b-img
              center
              slot="custom-image"
              :src="travelJournalImage"
              fluid
              alt="TravelJournal"
            ></b-img>
            <div slot="description">
              <p>
                Don't know where to go for your vacation? TravelJournal is your
                first destination in mind. Take a look at our journal, make your
                decision, and pack your suitcase.
              </p>
              <p>
                We are here to help you make informed decision, so your holiday
                will become enjoyable and unforgettable. Happy holiday!
              </p>
              <p class="highlighted">Utilized: HTML, CSS, JavaScript</p>
            </div>
            <b-button slot="button" class="button-dark big-button" disabled
              >Project currently not available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 13 -->
          <info-card id="disaster-escape-plan-project">
            <h2 slot="title">Disaster Escape Plan</h2>
            <p slot="tagline">
              Your one stop website for everything you need to prepare in case
              of natural disaster
            </p>
            <b-img
              center
              slot="custom-image"
              :src="disasterEscapePlanImage"
              fluid
              alt="Disaster Escape Plan"
            ></b-img>
            <div slot="description">
              <p>
                In response to Indonesia's Tsunami disaster in 2018, this
                website was created to provide essential information for the
                user related to the natural disaster that can happen anytime,
                anywhere.
              </p>
              <p>
                The user can visit the website to read about natural disasters
                and what actions need to be taken in case of emergency. This
                website also provides a checklist feature for the user to
                prepare a go-bag, which is an emergency bag that filled with
                essential things to be taken with during the disaster.
              </p>
              <p class="highlighted">
                Utilized: HTML, CSS, JavaScript, Bootstrap
              </p>
            </div>
            <b-button slot="button" class="button-dark big-button" disabled
              >Project currently not available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 14 -->
          <info-card id="tutor-me-project">
            <h2 slot="title">TutorMe</h2>
            <p slot="tagline">
              An insight of how the tutoring experience can be personalized for
              each student
            </p>
            <b-img
              center
              slot="custom-image"
              :src="tutorMeImage"
              fluid
              alt="TutorMe"
            ></b-img>
            <div slot="description">
              <p>
                TutorMe is a website that offer tutors for the students to help
                them study for a specific module. The tutoring experience can be
                tailored to meet the students personal preferences.
              </p>
              <p>
                The student will be provided with a list of available tutors
                that matched with the student requirement. The student can
                choose to contact the tutor that suitable with their learning
                style.
              </p>
              <p>
                The tutor can register and provide information about the
                knowledge and education background. The tutor can specify which
                course offered to be taught and the price for the tutoring
                service.
              </p>
              <p class="highlighted">
                Utilized: HTML, CSS, JavaScript, Bootstrap, PHP
              </p>
            </div>
            <b-button slot="button" class="button-dark big-button" disabled
              >Project currently not available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
    </content-section>
    <!-- FOOTER -->
    <footer-section theme="dark"></footer-section>
  </div>
</template>

<script>
import InformationCard from "@/components/InformationCard";
import ContentSection from "../components/ContentSection.vue";

export default {
  name: "SoftwareProjects",
  components: {
    "info-card": InformationCard,
    "content-section": ContentSection,
  },
  data() {
    return {
      // Image source link
      stuffToDoImage: require("@/assets/common/images/project/stuff-to-do-1.png"),
      prologImage: require("@/assets/common/images/project/project-prolog-3.png"),
      universityClassroomTimetableImage: require("@/assets/common/images/project/project-classroom-3.png"),
      bodyMindfulnessImage: require("@/assets/common/images/project/body-mindfulness-0.png"),
      climaImage: require("@/assets/common/images/project/clima-0.png"),
      xylophoneImage: require("@/assets/common/images/project/xylophone-0.png"),
      matrixManipulatorImage: require("@/assets/common/images/project/matrix-manipulator-0.png"),
      apartmentReservationSystemImage: require("@/assets/common/images/project/project-apartment-registration-system-3.png"),
      justSubmitImage: require("@/assets/common/images/project/justsubmit-3.png"),
      travelJournalImage: require("@/assets/common/images/project/project-traveljournal-3.png"),
      disasterEscapePlanImage: require("@/assets/common/images/project/project-disaster-escape-plan-3.png"),
      tutorMeImage: require("@/assets/common/images/project/project-tutorme-3.png"),

      // Carousel source link
      ymcCarousel: [
        {
          src: require("@/assets/common/images/project/ymc-0.png"),
        },
        {
          src: require("@/assets/common/images/project/ymc-1.png"),
        },
      ],
      galaxyShooterCarousel: [
        {
          src: require("@/assets/common/images/project/galaxy-shooter-1.png"),
        },
        {
          src: require("@/assets/common/images/project/galaxy-shooter-2.png"),
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
  mounted() {
    // reset page scroll position for multiple entry point across the pages
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
div#sectionTitle {
  height: 500px;
  line-height: 460px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./../assets/common/images/project/project-1.png");
  filter: grayscale(1);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  padding-bottom: 0;
  padding-top: 0;
}

div.sectionHeadline {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

ul {
  list-style-position: inside;
}
</style>
