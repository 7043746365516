<template>
  <div class="contact-me-container">
    <!-- CONTACT ME FORM -->
    <content-section theme="light">
      <form
        action="mailto:yosephmartin@yosephmartin.com?subject=I have something interesting to discuss"
        method="post"
        enctype="text/plain"
      >
        <b-row>
          <b-col class="text-center">
            <h1>Let's have an enjoyable conversation</h1>
            <p>
              Have anything to talk about with me? Just fill in the form, and I
              will reply to your message
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label class="input-field-label">Email address</label>
            <b-form-input
              id="email"
              type="text"
              placeholder="yosephmartin@yosephmartin.com"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label class="input-field-label">Subject</label>
            <b-form-input
              id="subject"
              type="text"
              placeholder="I have something interesting to discuss"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label class="input-field-label"
              >What do you want to talk about?</label
            >
            <b-form-textarea
              id="message"
              name="Message"
              type="text"
              placeholder="Enter your message here."
              rows="7"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" class="mr-2 button-light">Send</b-button>
            <b-button type="reset" class="button-light">Reset</b-button>
          </b-col>
        </b-row>
      </form>
    </content-section>
    <!-- CONTACT INFORMATION -->
    <div class="contact-information-container">
      <content-section theme="dark">
        <keep-in-touch-section
          title="Contact Information"
          subtitle="I am reachable by email and social media"
        />
      </content-section>
      <!-- FOOTER -->
      <footer-section></footer-section>
    </div>
  </div>
</template>

<script>
import KeepInTouch from "../components/KeepInTouch.vue";
import ContentSection from "../components/ContentSection.vue";

export default {
  name: "ContactMe",
  components: {
    "keep-in-touch-section": KeepInTouch,
    "content-section": ContentSection,
  },
};
</script>

<style scoped>
.contact-me-container {
  display: flex;
  flex-direction: column;
}
.contact-information-container {
  margin-top: auto;
}
.input-field-label {
  font-weight: bold;
}
</style>
