<template>
  <div>
    <!-- DARK THEME CARD -->
    <v-card hover dark v-if="theme === 'dark'">
      <v-container class="p-4">
        <v-row>
          <v-col class="text-center">
            <slot name="title"></slot>
            <slot name="tagline"></slot>
          </v-col>
        </v-row>
        <v-divider class="my-0"></v-divider>
        <v-row>
          <v-col cols="12" sm="12" lg="6">
            <v-container fill-height>
              <slot name="custom-carousel"></slot>
              <slot name="custom-image"></slot>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12" lg="6" class="text-justify">
            <v-container fill-height>
              <slot name="description"></slot>
              <slot name="button"></slot>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- LIGHT THEME CARD -->
    <v-card hover v-else>
      <v-container class="p-4">
        <v-row>
          <v-col class="text-center">
            <slot name="title"></slot>
            <slot name="tagline"></slot>
          </v-col>
        </v-row>
        <v-divider class="my-0"></v-divider>
        <v-row>
          <v-col cols="12" sm="12" lg="6">
            <v-container fill-height>
              <slot name="custom-carousel"></slot>
              <slot name="custom-image"></slot>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12" lg="6" class="text-justify">
            <v-container fill-height>
              <slot name="description"></slot>
              <slot name="button"></slot>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<style scoped></style>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "light",
    },
  },
};
</script>
