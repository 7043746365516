<template>
  <div>
    <!-- SECTION HEADLINE -->
    <div id="sectionTitle">
      <b-container>
        <b-row>
          <b-col></b-col>
          <b-col sm="12" md="10">
            <div class="sectionHeadline">
              <h1>Experiences &amp; Certifications</h1>
              <p>The experiences and certifications that I have achieved</p>
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-container>
    </div>
    <!-- WORK -->
    <content-section theme="dark">
      <b-row>
        <b-col class="text-center">
          <h1>Work Experiences</h1>
          <p>
            Time to put those hard earned skills into real-world implementation
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- WORK 1 -->
          <info-card theme="dark">
            <h2 slot="title">
              [Promotion] Senior Mobile Engineer (Hitachi Digital Host)
            </h2>
            <p slot="tagline">
              Through persistence in challenging times, promoted to Senior
              Mobile Engineer
              <b>(April 2023 - Present)</b>
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in hdhSeniorWorkExperienceCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                Not everyday the sun will shine, and the same also applies to
                the heavy rain. After long period of sleepless nights, I managed
                to perform and deliver my development products way above the
                expectation being put onto me.
              </p>
              <p>
                Overcoming a lot of challenging situations and learning many new
                stuff along the way, I have been granted
                <b>promotion to become Senior Mobile Engineer</b>. While I feel
                honoured to receive this promotion, I realize that there are
                still a lot of things waiting for me to be discovered.
                Sincerely, I hope that this is a new beginning to something much
                bigger ahead.
              </p>
              <p>
                Here are the brief summary of what I have experienced up to this
                point of time, on top of my previous role responsibility that I
                still need to perform.
              </p>
              <ul>
                <li>
                  Received <b>"Team Excellence Award 2023"</b> for being part of
                  the team that delivered project with the highest operating
                  profit and product deliverables quality for 2022 FY
                </li>
                <li>
                  Gained complete ownership and development independence to
                  perform planning, designing, refactoring, and developing
                  multiple front-end modules
                </li>
                <li>
                  Gave mentorship and guidance to other junior developers on how
                  to achieve their goals through time efficient and effective
                  implementations
                </li>
                <li>
                  Performed discussions with other Seniors and Technical Leads
                  on code standard and proper development techniques to achieve
                  implementation goals
                </li>
                <li>
                  Implemented well developed and properly tested code that
                  achieves >95% unit test coverage rate while maintaining
                  on-track deliverable schedule
                </li>
              </ul>
              <p></p>
            </div>
          </info-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <!-- WORK 2 -->
          <info-card theme="dark">
            <h2 slot="title">Mobile Engineer (Hitachi Digital Host)</h2>
            <p slot="tagline">
              The saga continues in Kuala Lumpur, Malaysia as a Mobile Engineer
              <b>(February 2021 - April 2023)</b>
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in hdhJuniorWorkExperienceCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                After graduated from the university, I decided to came back to
                the workplace that I loved and know very well. Until this
                moment, I am grateful for the right decision that I made for my
                first step of professional career.
              </p>
              <p>
                Familiar colleagues and working dynamics but this time with more
                responsibilities and higher expectations being put into me, it
                is time to quickly learning the ropes and making impactful
                contributions.
              </p>
              <p>
                Here are the brief summary of what I have experienced (TL;DR: As
                expected, it is even more awesome).
              </p>
              <ul>
                <li>
                  Developed the front-end side of financial services application
                  for mobile application using React Native and web application
                  using React JS alongside with extensive usage of TypeScript
                  for both platforms
                </li>
                <li>
                  Successfully integrated third-party solutions into the project
                  for enhanced transaction security, application
                  push-notifications (Airship), and app analytics using
                  Dynatrace and Firebase Crashlytics
                </li>
                <li>
                  Developed generally reusable UI components and functions that
                  could be reused in the project
                </li>
                <li>
                  Performed bug triage to find the root cause of a defect and
                  developed the necessary solution
                </li>
                <li>
                  Performed feature and logic integration between the front-end
                  and back-end side of the system
                </li>
                <li>
                  Implemented Jest unit testing for the UI components and
                  business logic of the system
                </li>
              </ul>
              <p></p>
            </div>
          </info-card>
        </b-col>
      </b-row>
    </content-section>

    <!-- INTERNSHIP -->
    <content-section theme="dark">
      <b-row>
        <b-col class="text-center">
          <h1>Internship</h1>
          <p>A brief introduction to the IT industry</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- INTERNSHIP 1 -->
          <info-card theme="dark">
            <h2 slot="title">Hokenso (Subsidiary of Hitachi eBworx)</h2>
            <p slot="tagline">
              Kuala Lumpur, Malaysia and Singapore as an Application Engineer
              Intern <b>(July 2019 - October 2019)</b>
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in hokensoInternshipCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                A very memorable 4-months internship program. Although this is
                my first internship experience and I was at my second year of
                university at that time, I was lucky enough to get accepted.
              </p>
              <p>
                The story did not stop there. 2 months after joining, the
                Project Manager trust my skills and decided to send me to the
                client site in Singapore. That was the part where I developed
                both my technical and interpersonal skills the most during the
                internship.
              </p>
              <p>
                Here are the brief summary of what I have experienced during the
                internship (TL;DR: It was an awesome experience).
              </p>
              <ul>
                <li>
                  Supported the System Integration Testing (SIT) in both
                  Malaysia and Singapore sites
                </li>
                <li>
                  Developed user interface and integrated the business logic
                  based on the high fidelity design proposal
                </li>
                <li>
                  Debugged and tested the application thoroughly to detect the
                  cause of design, logic, and back-end error
                </li>
                <li>
                  Proposed options of potential frameworks to be implemented to
                  achieve the project objectives
                </li>
                <li>
                  Performed technical knowledge transfer to new staffs to help
                  them understand the underlying project structure, logic flow,
                  and communication of each parts of the application
                </li>
              </ul>
              <p></p>
            </div>
          </info-card>
        </b-col>
      </b-row>
    </content-section>

    <!-- COMPETITION PARTICIPATION -->
    <content-section theme="dark">
      <b-row>
        <b-col class="text-center">
          <h1>Competition Participation</h1>
          <p>Hackathon and competition experience that I have gained</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- COMPETITION 1 -->
          <info-card theme="dark">
            <h2 slot="title">CIMB 3D Conquest (2018)</h2>
            <p slot="tagline">
              How we conquest the customer's feeling of data security by using
              biometric verification
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in cimbConquestCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                I was participated in this competition as a team. We need to
                pass multiple assessment process to be able to participate in
                the competition. We were assigned a task to solve a problem
                related to the mobile banking security. Our team came up with a
                solution, an Android application called ProLog, to secure the
                login process by using biometric authentication of the user.
              </p>
            </div>
            <router-link
              :to="{ name: 'softwareProjects', hash: '#prolog-project' }"
              slot="button"
              tag="b-button"
              class="button-dark big-button"
              >Go to ProLog project
            </router-link>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- COMPETITION 2 -->
          <info-card theme="dark">
            <h2 slot="title">NASA Space Apps Challenge (2018)</h2>
            <p slot="tagline">
              Multiple natural disasters, one specific solution for each
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in nasaSpaceAppsCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                In response to Indonesia's Tsunami in 2018, we want to create a
                website to provide essential information for the user to be
                prepared in case of natural disaster emergency. The team came up
                with Disaster Escape Plan, a website to educate and help people
                to prepare themselves, complete with specific action plan for
                each type of disaster.
              </p>
            </div>
            <router-link
              :to="{
                name: 'softwareProjects',
                hash: '#disaster-escape-plan-project',
              }"
              slot="button"
              tag="b-button"
              class="button-dark big-button"
              >Go to Disaster Escape Plan project
            </router-link>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- COMPETITION 3 -->
          <info-card theme="dark">
            <h2 slot="title">MMU 3 Days of Code Hackathon (2018)</h2>
            <p slot="tagline">
              All of us learn in unique way, and there is no one-size-fits-all
              method to teach everyone
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in mmuHackathonCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                My first team-based hackathon. We were assigned a task to
                develop a better solution to enhance the education sector and
                give student a better learning experience. We develop TutorMe,
                an online tutoring website that bring the tutor and student
                together based on the unique learning style of each student.
              </p>
            </div>
            <router-link
              :to="{ name: 'softwareProjects', hash: '#tutor-me-project' }"
              slot="button"
              tag="b-button"
              class="button-dark big-button"
              >Go to TutorMe project
            </router-link>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- COMPETITION 4 -->
          <info-card theme="dark">
            <h2 slot="title">E-Genting Programming Competition (2017, 2018)</h2>
            <p slot="tagline">Pushing coding ability to its very limit</p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in eGentingCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                This programming competition is held annually with the task to
                solve five hard programming question in form of accurate and
                efficient algorithm. I always participate in this competition
                each year to measure my coding ability.
              </p>
            </div>
          </info-card>
        </b-col>
      </b-row>
    </content-section>
    <!-- CERTIFICATIONS -->
    <content-section theme="light">
      <b-row>
        <b-col class="text-center">
          <h1>Certifications</h1>
          <p>Programming and application development certificates</p>
        </b-col>
      </b-row>
      <!-- CCA -->
      <b-row>
        <b-col>
          <showcase-card theme="light">
            <h2 slot="title">Cambridge Certification Authority (CCA)</h2>
            <b-container slot="showcase-content">
              <b-row>
                <b-col
                  sm="12"
                  lg="6"
                  v-for="(item, i) in ccaCertificationCarousel"
                  :key="i"
                >
                  <b-img :src="item.src" fluid></b-img>
                </b-col>
              </b-row>
            </b-container>
          </showcase-card>
        </b-col>
      </b-row>
      <!-- Dicoding -->
      <b-row>
        <b-col>
          <showcase-card theme="light">
            <h2 slot="title">Dicoding</h2>
            <b-container slot="showcase-content">
              <b-row>
                <b-col
                  sm="12"
                  lg="6"
                  v-for="(item, i) in dicodingCertificationCarousel"
                  :key="i"
                >
                  <b-img :src="item.src" fluid></b-img>
                </b-col>
              </b-row>
            </b-container>
          </showcase-card>
        </b-col>
      </b-row>
      <!-- Iverson -->
      <b-row>
        <b-col>
          <showcase-card theme="light">
            <h2 slot="title">Iverson</h2>
            <b-container slot="showcase-content">
              <b-row>
                <b-col
                  sm="12"
                  lg="6"
                  v-for="(item, i) in iversonCertificationCarousel"
                  :key="i"
                >
                  <b-img :src="item.src" fluid></b-img>
                </b-col>
              </b-row>
            </b-container>
          </showcase-card>
        </b-col>
      </b-row>
      <!-- Google -->
      <b-row>
        <b-col>
          <showcase-card theme="light">
            <h2 slot="title">Google</h2>
            <b-container slot="showcase-content">
              <b-row>
                <b-col
                  sm="12"
                  lg="6"
                  v-for="(item, i) in googleCertificationCarousel"
                  :key="i"
                >
                  <b-img :src="item.src" fluid></b-img>
                </b-col>
              </b-row>
            </b-container>
          </showcase-card>
        </b-col>
      </b-row>
    </content-section>
    <!-- ADDITIONAL LEARNING -->
    <content-section theme="light">
      <b-row>
        <b-col class="text-center">
          <h1>Additional Learning</h1>
          <p>Certificates from online courses and other sources</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- INSERT INFORMATION CARD HERE -->
          <showcase-card>
            <h2 slot="title">Udemy &amp; Pluralsight</h2>
            <b-container slot="showcase-content">
              <b-row>
                <b-col
                  sm="12"
                  lg="6"
                  v-for="(item, i) in additionalLearningCarousel"
                  :key="i"
                >
                  <b-img :src="item.src" fluid></b-img>
                </b-col>
              </b-row>
            </b-container>
          </showcase-card>
        </b-col>
      </b-row>
    </content-section>
    <!-- FOOTER -->
    <footer-section theme="dark"></footer-section>
  </div>
</template>

<script>
import InformationCard from "@/components/InformationCard";
import ShowcaseCard from "@/components/ShowcaseCard";
import ContentSection from "../components/ContentSection.vue";

export default {
  name: "Achievements",
  components: {
    "info-card": InformationCard,
    "showcase-card": ShowcaseCard,
    "content-section": ContentSection,
  },
  data() {
    return {
      hdhSeniorWorkExperienceCarousel: [
        {
          src: require("@/assets/common/images/work/hitachi-digital-host-3.png"),
        },
        {
          src: require("@/assets/common/images/work/hitachi-digital-host-4.png"),
        },
      ],
      hdhJuniorWorkExperienceCarousel: [
        {
          src: require("@/assets/common/images/work/hitachi-digital-host-1.png"),
        },
        {
          src: require("@/assets/common/images/work/hitachi-digital-host-2.png"),
        },
      ],
      hokensoInternshipCarousel: [
        {
          src: require("@/assets/common/images/internship/hokenso-internship-1.jpg"),
        },
        {
          src: require("@/assets/common/images/internship/hokenso-internship-2.jpg"),
        },
      ],
      cimbConquestCarousel: [
        {
          src: require("@/assets/common/images/certificate/cimb-3d-conquest.png"),
        },
        {
          src: require("@/assets/common/images/competition/competition-cimb-1-2018.png"),
        },
        {
          src: require("@/assets/common/images/competition/competition-cimb-2-2018.png"),
        },
      ],
      nasaSpaceAppsCarousel: [
        {
          src: require("@/assets/common/images/certificate/nasa-space-apps.png"),
        },
        {
          src: require("@/assets/common/images/competition/competition-nasa-2018.png"),
        },
      ],
      mmuHackathonCarousel: [
        {
          src: require("@/assets/common/images/certificate/mmu-hackathon.png"),
        },
        {
          src: require("@/assets/common/images/competition/competition-mmu-2018.png"),
        },
      ],
      eGentingCarousel: [
        {
          src: require("@/assets/common/images/certificate/e-genting-2018.png"),
        },
        {
          src: require("@/assets/common/images/competition/competition-egenting-1-2018.png"),
        },
        {
          src: require("@/assets/common/images/competition/competition-egenting-2-2018.png"),
        },
      ],
      ccaCertificationCarousel: [
        {
          src: require("@/assets/common/images/certificate/cca-html-level-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/cca-css-level-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/cca-javascript-level-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/cca-jquery-level-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/cca-mysql-level-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/cca-php-level-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/cca-java-level-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/cca-java-level-2.png"),
        },
      ],
      dicodingCertificationCarousel: [
        {
          src: require("@/assets/common/images/certificate/dicoding-0.png"),
        },
        {
          src: require("@/assets/common/images/certificate/dicoding-1.png"),
        },
        {
          src: require("@/assets/common/images/certificate/dicoding-2.png"),
        },
      ],
      iversonCertificationCarousel: [
        {
          src: require("@/assets/common/images/certificate/iverson-reactjs.png"),
        },
        {
          src: require("@/assets/common/images/certificate/iverson-react-native.png"),
        },
        {
          src: require("@/assets/common/images/certificate/iverson-react-with-typescript.png"),
        },
      ],
      googleCertificationCarousel: [
        {
          src: require("@/assets/common/images/certificate/google-fundamentals-of-digital-marketing.png"),
        },
      ],
      additionalLearningCarousel: [
        {
          src: require("@/assets/common/images/certificate/the-ultimate-guide-to-game-development-with-unity.png"),
        },
        {
          src: require("@/assets/common/images/certificate/pluralsight-practical-html5.png"),
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
div#sectionTitle {
  height: 500px;
  line-height: 460px;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("./../assets/common/images/certificate/certificate-headline.png");
  filter: grayscale(1);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  padding-bottom: 0;
  padding-top: 0;
}

div.sectionHeadline {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

ul {
  list-style-position: inside;
}
</style>
