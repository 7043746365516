<template>
  <div>
    <b-row>
      <b-col class="text-center">
        <h1>{{ title }}</h1>
        <p class="mb-0">
          {{ subtitle }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <form action="mailto:yosephmartin@yosephmartin.com">
          <b-button
            type="submit"
            variant="light"
            class="button-light big-button"
            >Hello, Martin!</b-button
          >
        </form>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <a href="https://github.com/MartinezBlaze" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'github-square']"
            class="fa-2x white-icon icon-margin-right"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/yoseph-martin-christian-a866416a/"
          target="_blank"
        >
          <font-awesome-icon
            :icon="['fab', 'linkedin']"
            class="fa-2x white-icon icon-margin-right"
          />
        </a>
        <a href="https://www.instagram.com/yosephmartin_" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'instagram']"
            class="fa-2x white-icon"
          />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100019932784015"
          target="_blank"
        >
          <font-awesome-icon
            :icon="['fab', 'facebook-square']"
            class="fa-2x white-icon icon-margin-left"
          />
        </a>
        <a href="https://twitter.com/Martinfrofile" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'twitter-square']"
            class="fa-2x white-icon icon-margin-left"
          />
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped></style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Keep in Touch!",
    },
    subtitle: {
      type: String,
      default:
        "If you want to reach me or have any interesting project idea to share, just drop me an email!",
    },
  },
};
</script>
