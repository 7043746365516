<template>
  <div
    v-if="theme === 'light'"
    :class="[
      'section-light',
      {
        'no-padding-top': noPaddingTop,
        'no-padding-bottom': noPaddingBottom,
      },
    ]"
  >
    <b-container>
      <slot></slot>
    </b-container>
  </div>
  <div
    v-else
    :class="[
      'section-dark',
      {
        'no-padding-top': noPaddingTop,
        'no-padding-bottom': noPaddingBottom,
      },
    ]"
  >
    <b-container>
      <slot></slot>
    </b-container>
  </div>
</template>

<style scoped>
.no-padding-top {
  padding-top: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.container-fluid {
  padding: 0;
}
</style>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "light",
    },
    noPaddingTop: {
      type: Boolean,
      default: false,
    },
    noPaddingBottom: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
