var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.theme === 'light')?_c('div',{class:[
    'section-light',
    {
      'no-padding-top': _vm.noPaddingTop,
      'no-padding-bottom': _vm.noPaddingBottom,
    },
  ]},[_c('b-container',[_vm._t("default")],2)],1):_c('div',{class:[
    'section-dark',
    {
      'no-padding-top': _vm.noPaddingTop,
      'no-padding-bottom': _vm.noPaddingBottom,
    },
  ]},[_c('b-container',[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }