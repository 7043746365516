<template>
    <div>
        <!-- DARK THEME CARD -->
        <v-card hover dark v-if="theme === 'dark'">
            <v-container>
                <v-row>
                    <v-col class="text-center">
                        <slot name="title"></slot>
                        <slot name="tagline"></slot>
                    </v-col>
                </v-row>
                <v-divider class="my-0"></v-divider>
                <slot name="showcase-content"></slot>
            </v-container>
        </v-card>

        <!-- LIGHT THEME CARD -->
        <v-card hover v-else>
            <v-container>
                <v-row>
                    <v-col class="text-center">
                        <slot name="title"></slot>
                        <slot name="tagline"></slot>
                    </v-col>
                </v-row>
                <v-divider class="my-0"></v-divider>
                <slot name="showcase-content"></slot>
            </v-container>
        </v-card>
    </div>
</template>

<style scoped>

</style>

<script>
export default {
    props: {
        theme: {
            type: String,
            default: 'light'
        }
    }
}
</script>
