<template>
  <div style="background-color: red">
    <!-- INTRODUCTION -->
    <content-section theme="light" noPaddingBottom>
      <b-row>
        <b-col sm="12" md="6" class="text-center" align-self="center">
          <p class="mb-0">Hello, my name is</p>
          <h1 class="mt-1">Yoseph Martin Christian</h1>
          <p>Welcome to my home on the internet.</p>
        </b-col>
        <b-col sm="12" md="6" class="pb-0">
          <b-img
            :src="yosephMartinChristianPrimaryPhoto"
            fluid
            alt="Yoseph Martin Christian - primary photo"
          ></b-img>
        </b-col>
      </b-row>
    </content-section>
    <!-- SUMMARY -->
    <content-section theme="dark">
      <b-row>
        <b-col class="text-center">
          <h1>A Summary of Myself</h1>
          <p>Let me summarize the story for you</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" class="text-justify">
          <p>
            I'm a software engineer who enjoys building user-friendly website
            and mobile application. Started coding since 14 years old, and found
            passion in the world of software engineering.
          </p>
          <p>
            I'm interested in learning about computer technologies and coding
            because I discovered that all the website, application, and software
            that I like to use were entirely built by code. Since then I always
            interested in learning how to code in various languages and keeping
            up with latest technologies.
          </p>
          <p>
            Here I am now. A software engineer that happy to experiment and
            break things, and somehow managed to restore the condition (that's
            why I always keep a backup of my work).
          </p>
        </b-col>
        <b-col sm="12" md="6" align-self="center">
          <b-img
            class="personal-image center-personal-image"
            :src="yosephMartinChristianSecondaryPhoto"
            fluid
            alt="Yoseph Martin Christian - secondary photo"
          ></b-img>
        </b-col>
      </b-row>
    </content-section>
    <!-- ACADEMIC BACKGROUND -->
    <content-section theme="light">
      <b-row>
        <b-col class="text-center" sm="12">
          <h1>Academic Background</h1>
          <p>Here are some academic information about myself</p>
        </b-col>
        <b-col sm="12" md="4">
          <h2 class="academic-section-title">
            Bachelor of Science (Honors) in Software Engineering
          </h2>
          <h3>
            Asia Pacific University, Malaysia & Staffordshire University, UK
            dual degree
          </h3>
        </b-col>
        <b-col sm="12" md="4">
          <h2 class="academic-section-title">Graduation date</h2>
          <h3>21st December 2020</h3>
        </b-col>
        <b-col sm="12" md="4">
          <h2 class="academic-section-title">Cumulative GPA</h2>
          <h3>3.76 / 4.00 (First Class)</h3>
        </b-col>
      </b-row>
    </content-section>
    <!-- TECHNICAL KNOW-HOW -->
    <content-section theme="dark">
      <b-row>
        <b-col class="text-center">
          <h1>Technical Know-how</h1>
          <p>
            Learn and understand. Here are some of the technologies that I am
            experienced in.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- TECHNICAL KNOW-HOW 1 -->
          <info-card id="website-development-technical-skills" theme="dark">
            <h2 slot="title">Website Development</h2>
            <p slot="tagline">
              Development skills necessary for the World Wide Web
            </p>
            <b-img
              center
              slot="custom-image"
              :src="websiteDevelopmentImage"
              fluid
              alt="Website Development Image"
            ></b-img>
            <div slot="description">
              <p class="highlighted">
                Website technologies used: HTML5, CSS3 and Sass, JavaScript,
                TypeScript, Vue.js, ReactJS, Bootstrap, Node.js, Jest (Unit
                Testing)
              </p>
              <p>
                Relevant project(s) that utilized one or more technologies in
                this category:
              </p>
              <ul>
                <li>
                  My personal website,
                  <span class="highlighted">yosephmartin.com</span> (You are
                  here)
                </li>
                <li>
                  Stuff To-Do (<span class="highlighted">thestufftodo.com</span
                  >)
                </li>
                <li>TravelJournal</li>
                <li>Disaster Escape Plan</li>
                <li>TutorMe</li>
              </ul>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- TECHNICAL KNOW-HOW 2 -->
          <info-card
            id="mobile-application-development-technical-skills"
            theme="dark"
          >
            <h2 slot="title">Mobile Application Development</h2>
            <p slot="tagline">
              Developing applications to be installed on your phone
            </p>
            <b-img
              center
              slot="custom-image"
              :src="mobileApplicationDevelopmentImage"
              fluid
              alt="Mobile Application Development Image"
            ></b-img>
            <div slot="description">
              <p class="highlighted">
                Mobile application development technologies used: React Native
                with TypeScript (Cross-platform), Java (Android), Kotlin
                (Android), Swift (iOS), Flutter (Cross-platform), Jest (Unit
                Testing)
              </p>
              <p>
                Relevant project(s) that utilized one or more technologies in
                this category:
              </p>
              <ul>
                <li>
                  Stuff To-Do (<span class="highlighted">thestufftodo.com</span
                  >)
                </li>
                <li>ProLog</li>
                <li>BodyMindfulness</li>
                <li>Clima</li>
                <li>Xylophone</li>
              </ul>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- TECHNICAL KNOW-HOW 3 -->
          <info-card
            id="desktop-application-development-technical-skills"
            theme="dark"
          >
            <h2 slot="title">Desktop Application Development</h2>
            <p slot="tagline">The application is executable</p>
            <b-img
              center
              slot="custom-image"
              :src="desktopApplicationDevelopmentImage"
              fluid
              alt="Desktop Application Development Image"
            ></b-img>
            <div slot="description">
              <p class="highlighted">
                Desktop application development technologies used: ASP.NET Core,
                C++, C#, Java, Python
              </p>
              <p>
                Relevant project(s) that utilized one or more technologies in
                this category:
              </p>
              <ul>
                <li>JustSubmit</li>
                <li>University Classroom Timetable System</li>
                <li>Matrix Manipulator</li>
                <li>Apartment Reservation System</li>
              </ul>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- TECHNICAL KNOW-HOW 4 -->
          <info-card
            id="database-and-server-side-technical-skills"
            theme="dark"
          >
            <h2 slot="title">Database & Server Side</h2>
            <p slot="tagline">
              Beautiful front-end should be integrated with functioning back-end
            </p>
            <b-img
              center
              slot="custom-image"
              :src="databaseAndServerSideImage"
              fluid
              alt="Database and Server Side Image"
            ></b-img>
            <div slot="description">
              <p class="highlighted">
                Database & server side technologies used: PHP, MySQL, Firebase,
                Microsoft Azure
              </p>
              <p>
                Relevant project(s) that utilized one or more technologies in
                this category:
              </p>
              <ul>
                <li>
                  Stuff To-Do (<span class="highlighted">thestufftodo.com</span
                  >)
                </li>
                <li>JustSubmit</li>
              </ul>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- TECHNICAL KNOW-HOW 5 -->
          <info-card
            id="game-application-development-technical-skills"
            theme="dark"
          >
            <h2 slot="title">Game Application Development</h2>
            <p slot="tagline">Rather than playing games, why not create one?</p>
            <b-img
              center
              slot="custom-image"
              :src="gameApplicationDevelopmentImage"
              fluid
              alt="Game Application Development Image"
            ></b-img>
            <div slot="description">
              <p class="highlighted">
                Game application technologies used: Unity
              </p>
              <p>
                Relevant project(s) that utilized one or more technologies in
                this category:
              </p>
              <ul>
                <li>Galaxy Shooter</li>
              </ul>
            </div>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- TECHNICAL KNOW-HOW 6 -->
          <info-card id="version-control-technical-skills" theme="dark">
            <h2 slot="title">Version Control</h2>
            <p slot="tagline">
              Before you plan to modify anything, back it up first
            </p>
            <b-img
              center
              slot="custom-image"
              :src="versionControlImage"
              fluid
              alt="Version Control Image"
            ></b-img>
            <div slot="description">
              <p class="highlighted">
                Version control technologies used: Git, GitHub, GitLab,
                BitBucket, Sourcetree
              </p>
              <p>
                Relevant project(s) that utilized one or more technologies in
                this category:
              </p>
              <ul>
                <li>
                  Almost all projects mentioned used one or more specified
                  technologies
                </li>
              </ul>
            </div>
          </info-card>
        </b-col>
      </b-row>
    </content-section>
    <!-- HIGHLIGHTED PROJECTS -->
    <content-section theme="light">
      <b-row>
        <b-col class="text-center">
          <h1>Highlighted Projects</h1>
          <p>Learning by doing. Here are some of my best software projects.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 1 -->
          <info-card id="ymc-project">
            <h2 slot="title">My Personal Website</h2>
            <p slot="tagline">
              Very first effort to put my mark on the world wide web |
              <strong>http://yosephmartin.com</strong>
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in ymcCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                Initially started as a personal documentation of my development
                journey since 2018, my personal website have gone through
                multiple major improvements until today. Every time there is any
                milestone that I have achieved, no matter how big or small, this
                website will be the first place that knows it. Throughout the
                time, the way I saw this website evolved from a place to
                showcase my achievements into becoming more deeper as part of my
                true identity.
              </p>
              <p>
                Please take your time to explore around my home, and enjoy your
                stay while you are here.
              </p>
              <p class="highlighted">
                Utilized: HTML, CSS, JavaScript, Vue.js, BootstrapVue, cPanel
              </p>
            </div>
            <b-button slot="button" class="button-dark big-button" disabled
              ><strong>Congratulations, you are here!</strong>
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 2 -->
          <info-card id="stufftodo-project">
            <h2 slot="title">Stuff To-Do</h2>
            <p slot="tagline">
              Every one of us has a lot of #StuffToDo |
              <strong>http://thestufftodo.com</strong>
            </p>
            <b-img
              center
              slot="custom-image"
              :src="stuffToDoImage"
              fluid
              alt="Stuff To-Do"
            ></b-img>
            <div slot="description">
              <p>
                Stuff To-Do is a cross-platform collaborative to-do list
                application that is available on web and mobile (Android)
                platforms. Both of these platforms have real-time data
                synchronization feature that enables Stuff To-Do to always use
                the latest data, no matter in which platform you use Stuff
                To-Do.
              </p>
              <p>
                Stuff to-do will make you able to easily manage both your
                personal or collaborative tasks. With the personal list, you
                will be able to make your own personalized list that suits your
                needs.
              </p>
              <p>
                The collaborative list, however, is the heart of Stuff To-Do.
                Stuff To-Do was developed with collaboration in mind, and thus
                designed in such a way that can enhance the collaboration
                experience through the collaborator role management feature.
              </p>
              <p>
                With Stuff To-Do, revolutionary features will be introduced and
                to-do list will not be the same anymore.
              </p>
              <p class="highlighted">
                Utilized: HTML, CSS, JavaScript, Vue.js, BootstrapVue, Android,
                Java, XML, cPanel, Firebase Firestore
              </p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="openLink('http://thestufftodo.com')"
              >Access it on <strong>thestufftodo.com</strong>
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 3 -->
          <info-card>
            <h2 slot="title">Galaxy Shooter</h2>
            <p slot="tagline">
              A futuristic sci-fi galactic shooter against the unknown enemy in
              the deep space
            </p>
            <b-carousel slot="custom-carousel" controls indicators>
              <b-carousel-slide
                v-for="(item, i) in galaxyShooterCarousel"
                :key="i"
                :img-src="item.src"
              >
              </b-carousel-slide>
            </b-carousel>
            <div slot="description">
              <p>
                A 2D arcade game that took place in the galaxy that included a
                spaceship battle. This game was made based on the Unity
                Authorized Course for game developer training.
              </p>
              <p class="highlighted">Utilized: C#, Unity</p>
              <p>How to play:</p>
              <ul>
                <li>Press the spacebar to start the game</li>
                <li>Use W, A, S, D key to move</li>
                <li>Use left mouse button to shoot</li>
                <li>
                  Grab power ups along the way and survive as long as you can!
                </li>
              </ul>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="
                openLink(
                  'http://yosephmartin.com/demo/space-shooter/index.html'
                )
              "
              >Play the game!
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- PROJECT 4 -->
          <info-card id="prolog-project">
            <h2 slot="title">ProLog</h2>
            <p slot="tagline">
              Biometric authentication application that brings a peace of mind
              to your data security
            </p>
            <b-img
              center
              slot="custom-image"
              :src="prologImage"
              fluid
              alt="ProLog"
            ></b-img>
            <div slot="description">
              <p>
                ProLog is an Android application that enable the user to do
                two-factor authentication that include user's biometric
                information for added security to access the data stored in the
                phone. At the first factor of authentication, the user can
                choose the preferred authentication method, which consist of:
              </p>
              <ul>
                <li>Password</li>
                <li>PIN</li>
                <li>Pattern</li>
              </ul>
              <p>
                After the user passed the first authentication, the second
                authentication method will be performed by using user's
                biometrics, which consist of:
              </p>
              <ul>
                <li>Fingerprint</li>
                <li>Voice*</li>
                <li>Face recognition**</li>
              </ul>
              <p>
                If the user can provide the biometric information, the user can
                access the data inside the application.
              </p>
              <p>
                * The voice feature is not recognizing the user's voice, instead
                of comparing the password phrase that can be customized by the
                user at initial application setup.
              </p>
              <p>
                ** The face recognition feature will become available in the
                future version.
              </p>
              <p class="highlighted">Utilized: Android, Java, XML</p>
            </div>
            <b-button
              slot="button"
              class="button-dark big-button"
              @click="openLink('https://github.com/MartinezBlaze/ProLog')"
              >Project available on GitHub
            </b-button>
          </info-card>
        </b-col>
      </b-row>
      <!-- Learn more -->
      <b-row class="text-center">
        <b-col>
          <h2 class="mb-0">
            Interested to learn more about my projects? Take a look at my
            portfolio!
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <router-link
            :to="{ name: 'softwareProjects' }"
            slot="button"
            tag="b-button"
            class="button-dark big-button"
          >
            More Projects
          </router-link>
        </b-col>
      </b-row>
    </content-section>
    <!-- KEEP IN TOUCH -->
    <content-section theme="dark">
      <keep-in-touch-section
        title="Keep in Touch!"
        subtitle="If you want to reach me or have any interesting project idea to share, just drop me an email!"
      />
    </content-section>
    <!-- FOOTER -->
    <footer-section></footer-section>
  </div>
</template>

<script>
import InformationCard from "@/components/InformationCard";
import KeepInTouch from "../components/KeepInTouch.vue";
import ContentSection from "../components/ContentSection.vue";

export default {
  name: "AboutMe",
  components: {
    "info-card": InformationCard,
    "keep-in-touch-section": KeepInTouch,
    "content-section": ContentSection,
  },
  data() {
    return {
      // Image source link
      yosephMartinChristianPrimaryPhoto: require("@/assets/common/images/personal/formal-0.jpg"),
      yosephMartinChristianSecondaryPhoto: require("@/assets/common/images/personal/formal-1.jpg"),

      // Technical Know-how source link
      websiteDevelopmentImage: require("@/assets/common/images/icon/technical-skills/website-development.png"),
      mobileApplicationDevelopmentImage: require("@/assets/common/images/icon/technical-skills/mobile-application-development.png"),
      desktopApplicationDevelopmentImage: require("@/assets/common/images/icon/technical-skills/desktop-application-development.png"),
      databaseAndServerSideImage: require("@/assets/common/images/icon/technical-skills/database-and-server-side.png"),
      gameApplicationDevelopmentImage: require("@/assets/common/images/icon/technical-skills/game-application-development.png"),
      versionControlImage: require("@/assets/common/images/icon/technical-skills/version-control.png"),

      // Project source link
      stuffToDoImage: require("@/assets/common/images/project/stuff-to-do-1.png"),
      prologImage: require("@/assets/common/images/project/project-prolog-3.png"),

      // Carousel source link
      ymcCarousel: [
        {
          src: require("@/assets/common/images/project/ymc-0.png"),
        },
        {
          src: require("@/assets/common/images/project/ymc-1.png"),
        },
      ],
      galaxyShooterCarousel: [
        {
          src: require("@/assets/common/images/project/galaxy-shooter-1.png"),
        },
        {
          src: require("@/assets/common/images/project/galaxy-shooter-2.png"),
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
img.personal-image {
  width: 225px;
}

img.center-personal-image {
  display: block;
  box-shadow: 7px 7px 0 0 rgba(255, 255, 255, 0.5);
  margin: 10px auto;
}

.academic-section-title {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid black;
}

.skill-table,
.skill-table th,
.skill-table td {
  border: 1px solid black;
  width: 325px;
  padding: 0.75rem;
  margin-left: auto;
  margin-right: auto;
}

ul {
  list-style-position: inside;
}
</style>
