import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Vuetify
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {};

export default new Vuetify(opts);

// custom CSS
import "@/styles.css";

// BootstrapVue
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// BoostrapVue CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// import font-awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGithubSquare,
  faLinkedin,
  faInstagram,
  faFacebookSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import global components
import Footer from "./components/Footer";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("footer-section", Footer);

library.add(
  faGithubSquare,
  faLinkedin,
  faInstagram,
  faFacebookSquare,
  faTwitterSquare
);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
