import { render, staticRenderFns } from "./AboutMe.vue?vue&type=template&id=6b429b8c&scoped=true"
import script from "./AboutMe.vue?vue&type=script&lang=js"
export * from "./AboutMe.vue?vue&type=script&lang=js"
import style0 from "./AboutMe.vue?vue&type=style&index=0&id=6b429b8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b429b8c",
  null
  
)

export default component.exports