<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <router-link to="/" tag="b-navbar-brand"
      ><img src="@/assets/common/images/ymc-logo-white.png" width="75" />
    </router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <router-link to="/" tag="b-nav-item" active-class="active" exact
          >About Me</router-link
        >
        <router-link
          to="/softwareProjects"
          tag="b-nav-item"
          active-class="active"
          exact
          >Software Projects</router-link
        >
        <router-link to="/experiences" tag="b-nav-item" active-class="active"
          >Experiences &amp; Certifications
        </router-link>
        <router-link to="/activities" tag="b-nav-item" active-class="active"
          >Activities</router-link
        >
        <router-link to="/contactMe" tag="b-nav-item" active-class="active"
          >Contact Me</router-link
        >
        <b-button
          @click="
            openLink(
              'http://yosephmartin.com/resume/YosephMartinChristian_Resume.pdf',
              '_blank'
            )
          "
          variant="light"
          class="button-light"
          >Resume
        </b-button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "Navigation",
  methods: {
    openLink(link, target) {
      window.open(link, target);
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: black !important;
}

.button-light {
  background-color: white;
  color: black;
}

.button-light:hover {
  background-color: black;
  color: white;
}

.button-dark {
  background-color: black;
  color: white;
}

.button-dark:hover {
  background-color: white;
  color: black;
}
</style>
