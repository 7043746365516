import Vue from "vue";
import VueRouter from "vue-router";

// import the necessary path
import Navigation from "@/components/Navigation";
import AboutMe from "@/views/AboutMe";
import Experiences from "@/views/Experiences";
import Activities from "@/views/Activities";
import ContactMe from "@/views/ContactMe";
import SoftwareProjects from "@/views/SoftwareProjects";

// user the VueRouter
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "aboutMe",
    components: {
      default: AboutMe,
      navigation: Navigation,
    },
    meta: {
      title: "Yoseph Martin Christian - Software Engineer",
      metaTags: [
        {
          name: "description",
          content: "The home page of Yoseph Martin Christian",
        },
      ],
    },
  },
  {
    path: "/experiences",
    name: "experiences",
    components: {
      default: Experiences,
      navigation: Navigation,
    },
    meta: {
      title: "Experiences & Certifications | Yoseph Martin Christian",
      metaTags: [
        {
          name: "description",
          content: "The experiences & certifications page of yosephmartin.com",
        },
      ],
    },
  },
  {
    path: "/activities",
    name: "activities",
    components: {
      default: Activities,
      navigation: Navigation,
    },
    meta: {
      title: "Activities | Yoseph Martin Christian",
      metaTags: [
        {
          name: "description",
          content: "The activities page of yosephmartin.com",
        },
      ],
    },
  },
  {
    path: "/contactMe",
    name: "contactMe",
    components: {
      default: ContactMe,
      navigation: Navigation,
    },
    meta: {
      title: "Contact Me | Yoseph Martin Christian",
      metaTags: [
        {
          name: "description",
          content: "The contact me page of yosephmartin.com",
        },
      ],
    },
  },
  {
    path: "/softwareProjects",
    name: "softwareProjects",
    components: {
      default: SoftwareProjects,
      navigation: Navigation,
    },
    meta: {
      title: "Software Projects | Yoseph Martin Christian",
      metaTags: [
        {
          name: "description",
          content: "The software projects page of yosephmartin.com",
        },
      ],
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
});

// set the title of the page
const DEFAULT_TITLE = "Yoseph Martin Christian's Personal Website";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
